import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Banner from './Banner';
import Fproduct from './Fproduct';
import TestimonialSlider from './TestimonialSlider';
import Footer from './Footer';
import ServicePage from './Service';
import Products from './Products';
import About from './About';
import Contact from './Contact';
import ScrollToTop from './ScrollToTop';

const App = () => {
  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Routes>
        {/* Home Page Route (App.js acting as the home page) */}
        <Route
          path="/"
          element={
            <>
              <Banner />
              <Fproduct />
              <TestimonialSlider />
              <Footer />
            </>
          }
        />

        {/* Services Page Route */}
        <Route path="/services" element={<ServicePage />} />

        {/* Product Page Route */}
        <Route path="/products" element={<Products />} />

        {/* About Page Route */}
        <Route path="/about" element={<About />} />

        {/* Contact Page Route */}
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
