import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Footer from './Footer';

const AboutUs = () => {
  return (
    <>
      {/* Banner Section */}
      <section className="relative bg-cover bg-center bg-no-repeat h-72 flex items-center justify-center" 
        style={{ backgroundImage: 'url(https://example.com/path-to-your-banner-image.jpg)' }}>
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900 to-blue-700 opacity-75"></div>
        <h2 className="relative text-4xl md:text-5xl font-bold text-white text-center">About Us</h2>
      </section>
      
      {/* Content Section */}
      <section className="py-12 bg-white">
        <div className="max-w-screen-xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div className="flex flex-col justify-center">
              <h3 className="text-2xl font-semibold text-blue-900 mb-4">Who We Are</h3>
              <p className="text-blue-700 mb-6">
                Unique Technology is a leading provider of top-tier jewellery manufacturing machines and expert consultancy services. 
                We specialize in offering customized machine setups, comprehensive production planning, and advanced training for efficient 
                and effective manufacturing operations.
              </p>
              <p className="text-blue-700 mb-6">
                Our team of experts is dedicated to helping businesses optimize their manufacturing processes, improve production efficiency, 
                and achieve high-quality results. We pride ourselves on our commitment to excellence and customer satisfaction.
              </p>
            </div>

            <div className="flex justify-center items-center">
              <img
                src="https://example.com/path-to-your-team-photo.jpg" // Replace with actual image URL
                alt="Our Team"
                className="w-full h-80 object-cover rounded-lg shadow-md"
              />
            </div>
          </div>

          <div className="mt-12 text-center">
            <h3 className="text-2xl font-semibold text-blue-900 mb-4">Our Mission</h3>
            <p className="text-blue-700 mb-6">
              Our mission is to empower businesses with state-of-the-art machinery and expert guidance to enhance their jewellery manufacturing processes. 
              We aim to provide innovative solutions that drive productivity, reduce manual labor, and ensure top-notch quality in every piece produced.
            </p>
            <Link to="/Contact" className="inline-block bg-blue-700 text-white px-6 py-3 rounded-md font-semibold hover:bg-blue-600 transition duration-300">
              Get In Touch
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AboutUs;
