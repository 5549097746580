import React, { useState } from 'react';
import { CiMenuBurger } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import { Link } from 'react-router-dom';

const Header = () => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <header className="bg-[#f0f4f8] fixed w-full z-50 shadow-lg">
      <div className="max-w-screen-xl mx-auto flex justify-between items-center p-4">
        {/* Logo and Company Name */}
        <div className="flex items-center">
          <div className="w-14 h-14 sm:w-12 sm:h-12 flex items-center justify-center mr-2">
            <img src="logo.png" alt="UniqueTechnology Logo" className="w-14 h-10 object-cover" />
          </div>
          <div className="text-xl sm:text-xl font-bold text-blue-900 whitespace-nowrap">
            UniqueTechnology
          </div>
        </div>

        {/* Burger Menu / Close Icon (Mobile) */}
        <div className="md:hidden text-3xl cursor-pointer text-blue-900 z-50" onClick={handleToggle}>
          {toggle ? <IoIosClose className="w-8 h-8 sm:w-6 sm:h-6 text-blue-900 z-50" /> : <CiMenuBurger className="w-8 h-8 sm:w-6 sm:h-6 text-blue-900 z-50" />}
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-8">
          <Link to="/" className="relative group text-blue-900 hover:text-blue-700">
            Home
            <span className="block h-1 bg-blue-700 absolute bottom-0 left-0 w-0 group-hover:w-full transition-all duration-300"></span>
          </Link>
          <Link to="/services" className="relative group text-blue-900 hover:text-blue-700">
            Services
            <span className="block h-1 bg-blue-700 absolute bottom-0 left-0 w-0 group-hover:w-full transition-all duration-300"></span>
          </Link>
          <Link to="/products" className="relative group text-blue-900 hover:text-blue-700">
            Products
            <span className="block h-1 bg-blue-700 absolute bottom-0 left-0 w-0 group-hover:w-full transition-all duration-300"></span>
          </Link>
          <Link to="/about" className="relative group text-blue-900 hover:text-blue-700">
            About
            <span className="block h-1 bg-blue-700 absolute bottom-0 left-0 w-0 group-hover:w-full transition-all duration-300"></span>
          </Link>
          <Link to="/contact" className="relative group text-blue-900 hover:text-blue-700">
            Contact
            <span className="block h-1 bg-blue-700 absolute bottom-0 left-0 w-0 group-hover:w-full transition-all duration-300"></span>
          </Link>
        </nav>

        {/* Mobile Navigation */}
        <nav className={`md:hidden fixed inset-0 bg-blue-900 bg-opacity-95 z-40 transition-transform duration-300 ${toggle ? 'translate-x-0' : 'translate-x-full'}`}>
          <ul className="flex flex-col items-center justify-center h-full space-y-8 text-white">
            <li>
              <Link to="/" onClick={handleToggle} className="text-2xl hover:text-blue-300">
                Home
              </Link>
            </li>
            <li>
              <Link to="/services" onClick={handleToggle} className="text-2xl hover:text-blue-300">
                Services
              </Link>
            </li>
            <li>
              <Link to="/products" onClick={handleToggle} className="text-2xl hover:text-blue-300">
                Products
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={handleToggle} className="text-2xl hover:text-blue-300">
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={handleToggle} className="text-2xl hover:text-blue-300">
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
