import React, { useState } from 'react';
import Footer from './Footer';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formspreeURL = 'https://formspree.io/f/xwpejago'; // Replace 'yourFormID' with your actual Formspree ID

    fetch(formspreeURL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          // Show popup and reset form on successful submission
          setShowPopup(true);
          setFormData({
            name: '',
            phone: '',
            email: '',
            message: '',
          });
        } else {
          console.error('Form submission error:', response.statusText);
        }
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
      });
  };

  return (
    <>
      {/* Banner Section */}
      <section className="relative bg-cover bg-center bg-no-repeat h-72 flex items-center justify-center" 
        style={{ backgroundImage: 'url(https://example.com/path-to-your-banner-image.jpg)' }}>
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900 to-blue-700 opacity-75"></div>
        <h2 className="relative text-4xl md:text-5xl font-bold text-white text-center">Contact Us</h2>
      </section>

      {/* Content Section */}
      <section className="py-12 bg-gray-100">
        <div className="max-w-screen-xl mx-auto px-4 flex flex-col md:flex-row">
          {/* Left Side: Form */}
          <div className="md:w-1/2">
            <form onSubmit={handleSubmit} className="bg-white shadow-lg rounded-lg p-8">
              <div className="mb-4">
                <label className="block text-blue-900 text-sm font-bold mb-2" htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-blue-900 leading-tight focus:outline-none focus:shadow-outline transition-transform transform hover:scale-105"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-blue-900 text-sm font-bold mb-2" htmlFor="phone">Phone</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-blue-900 leading-tight focus:outline-none focus:shadow-outline transition-transform transform hover:scale-105"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-blue-900 text-sm font-bold mb-2" htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-blue-900 leading-tight focus:outline-none focus:shadow-outline transition-transform transform hover:scale-105"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-blue-900 text-sm font-bold mb-2" htmlFor="message">Message</label>
                <textarea
                  name="message"
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-blue-900 leading-tight focus:outline-none focus:shadow-outline transition-transform transform hover:scale-105"
                  rows="4"
                  required
                />
              </div>

              <div className="text-center">
                <button type="submit" className="bg-blue-700 text-white px-6 py-3 rounded-md font-semibold hover:bg-blue-600 transition duration-300 transform hover:scale-105">
                  Submit
                </button>
              </div>
            </form>

            {showPopup && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg text-center animate__animated animate__fadeIn">
                  <h3 className="text-2xl font-bold text-blue-900 mb-4">Form Submitted Successfully!</h3>
                  <p>Your message has been sent. We will get back to you soon.</p>
                  <button
                    onClick={() => setShowPopup(false)}
                    className="mt-6 bg-blue-700 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Right Side: Contact Details & Google Map */}
          <div className="md:w-1/2 md:pl-8 mt-8 md:mt-0">
            <div className="bg-white shadow-lg rounded-lg p-8 mb-8">
              <h3 className="text-2xl font-bold text-blue-900 mb-4">Contact Information</h3>
              <p className="text-blue-900 mb-2"><strong>Email:</strong> uniquetechnology168@gmail.com</p>
              <p className="text-blue-900 mb-2"><strong>Phone:</strong> 9352589519</p>
              <p className="text-blue-900 mb-4"><strong>Address:</strong></p>
              <p className="text-blue-900 mb-4">
                EPIP Gems and Jewellery Zone<br />
                Sitapura Industrial Area<br />
                Jaipur 302022
              </p>
              <p className="text-blue-900 mb-4">Feel free to reach out to us for any inquiries or collaboration opportunities.</p>
            </div>

            {/* Google Map Embed */}
            <div className="bg-white shadow-lg rounded-lg p-8">
              <h3 className="text-2xl font-bold text-blue-900 mb-4">Find Us</h3>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1952.1794415304585!2d75.82722726173263!3d26.786632813570623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396dc99f9e4c9509%3A0xae4f5cfeb3bf5985!2sG1-31%2C%20Sitapura%20Industrial%20Area%2C%20Sitapura%2C%20Jaipur%2C%20Rajasthan%20302022!5e0!3m2!1sen!2sin!4v1726568870855!5m2!1sen!2sin"
                width="100%"
                height="250"
                style={{ border: '0' }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Contact;
