import React from 'react';
import Footer from './Footer';

const products = [
  {
    id: 1,
    name: 'EPAG-SMART',
    description: 'The new OTEC EPAG-Smart is perfect for smoothing and polishing intricate jewellery. It polishes hard-to-reach inner contours without compromising intricate geometries. Finishing stone-set jewellery is easy in the EPAG-Smart. It combines top quality with high cost-effectiveness, minimising the need for manual polishing while also producing outstanding results.',
    imageUrl: 'otec1.png',
    pdfUrl: 'EPAG-Smart-FLYER.pdf',
  },
  {
    id: 2,
    name: 'Elmasonic xtra ST',
    description: 'Elmasonic xtra ST floor-mounted ultrasonic devices are designed for use in a production environment, workshops, and servicing. The wide range of features makes it easy and effective to work with these devices.',
    imageUrl: 'elmasonic.png',
    pdfUrl: 'Elmasonic_xtra_ST_EN-3.pdf',
  },
  {
    id: 3,
    name: 'ECO Series',
    description: 'The centrifugal disc finishing process is a mass finishing process specially developed for the surface treatment of workpieces. A rotating motion of the process container causes a relative motion between the workpiece and abrasive, which creates a material abrasion and thus a surface finishing on the workpiece.',
    imageUrl: 'otec2.png',
    pdfUrl: 'ECO-SERIES.pdf',
  },
  {
    id: 4,
    name: 'EF-FLEX',
    description: 'Processing of delicate and intricate pieces of jewellery with complex geometries requires particularly gentle smoothing and polishing. The EF-Flex reaches a new dimension of sparkling brilliance even on pieces of jewellery set with stones.',
    imageUrl: 'otec3.jpg',
    pdfUrl: 'flex.pdf',
  },
];

const ProductPage = () => {
  return (
    <>
      {/* Banner Section */}
      <section className="relative bg-cover bg-center bg-no-repeat h-72 flex items-center justify-center" 
        style={{ backgroundImage: 'url(https://example.com/path-to-your-banner-image.jpg)' }}>
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900 to-blue-700 opacity-75"></div>
        <h2 className="relative text-4xl md:text-5xl font-bold text-white text-center">Our Products</h2>
      </section>

      {/* Products Section */}
      <section className="py-24 bg-gradient-to-r from-blue-50 to-blue-100">
        <div className="max-w-screen-xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {products.map(product => (
              <div key={product.id} className="bg-white rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500 ease-in-out flex flex-col">
                <img 
                  src={product.imageUrl} 
                  alt={product.name} 
                  className="w-full h-56 object-cover rounded-t-lg"
                />
                <div className="p-6 flex-1 flex flex-col">
                  <h3 className="text-2xl font-bold text-blue-900 mb-4">{product.name}</h3>
                  <p className="text-blue-700 mb-6 flex-1">{product.description}</p>
                  <a 
                    href={product.pdfUrl} 
                    download 
                    className="inline-block bg-blue-700 text-white px-6 py-3 rounded-md font-semibold hover:bg-blue-600 transition duration-300 mt-auto"
                  >
                    Download PDF
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProductPage;
