import React from 'react';
import { useNavigate } from 'react-router-dom';

const Fproduct = () => {
  const navigate = useNavigate(); // React Router's useNavigate hook for navigation

  const products = [
    { name: 'Elmasonic xtra ST', description: 'High-efficiency cleaning machine by Elma.', image: 'elma1.png' },
    { name: 'EF-FLEX', description: 'High-gloss polishing reduces manual work to a minimum.', image: 'otec3.jpg' },
    { name: '3D Printing Technology', description: 'Versatile manufacturing machine by Uniway.', image: 'uniway1.png' },
    { name: 'ExJet A5 NSP', description: 'Advanced technology machine by Uniway.', image: 'uniway2.png' },
    { name: 'EPAG-SMART', description: 'Polishing and deburring machine by Otec.', image: 'otec1.png' },
    { name: 'ECO Series', description: 'High-performance finishing machine by Otec.', image: 'otec2.png' },
  ];

  const handleExploreMore = () => {
    navigate('/products'); // Redirect to the products page
  };

  return (
    <section className="py-12 bg-[#f0f4f8]">
      <div className="max-w-screen-xl mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-blue-900 mb-12">Featured Products</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 transform hover:scale-105 hover:shadow-2xl group">
              {/* Card Image with Hover Zoom Effect */}
              <div className="relative overflow-hidden">
                <img 
                  src={product.image} 
                  alt={product.name} 
                  className="w-full h-48 object-cover transition-transform duration-500 group-hover:scale-110" 
                />
              </div>
              
              {/* Card Content */}
              <div className="p-6">
                <h3 className="text-xl font-bold text-blue-900 mb-2 transition-transform duration-300 transform group-hover:scale-105">{product.name}</h3>
                <p className="text-blue-700 mb-4 transition-opacity duration-300 opacity-100 group-hover:opacity-70">{product.description}</p>
                <button 
                  onClick={handleExploreMore} 
                  className="inline-block bg-blue-700 text-white px-4 py-2 rounded-md font-semibold transition-transform duration-300 transform hover:scale-105 hover:bg-blue-600 hover:shadow-lg"
                >
                  Explore More
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Fproduct;
