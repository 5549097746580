import React from 'react';

const ClientSlider = () => {
  const clients = [
    { id: 1, icon: 'icon1.jpeg', name: 'Vaibhav Global Limited' },
    { id: 2, icon: 'icon2.png', name: 'KGK group' },
    { id: 3, icon: 'icon3.png', name: 'UV overseas' },
    { id: 4, icon: 'gallant.png', name: 'Gallant' },
    { id: 5, icon: 'icon5.png', name: 'Derewala' },
  ];

  return (
    <section className="py-12 bg-[#f0f4f8]">
      <div className="max-w-screen-xl mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-blue-900 mb-8">Our Clients</h2>
        <div className="relative overflow-hidden">
          <div className="slider-container">
            <div className="slider-track">
              {/* Render client logos and names twice for continuous scrolling */}
              {clients.concat(clients).map((client, index) => (
                <div
                  key={index} // Using index as key due to duplication
                  className="client-item flex-shrink-0 w-40 md:w-52 lg:w-64 bg-white p-4 rounded-lg shadow-lg mx-4"
                >
                  <img
                    src={client.icon}
                    alt={client.name}
                    className="w-16 h-16 object-cover rounded-full mx-auto mb-4"
                  />
                  <h3 className="text-lg font-semibold text-blue-900 text-center">{client.name}</h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Add CSS for continuous sliding */}
      <style jsx>{`
        .slider-container {
          width: 100%;
          overflow: hidden;
        }

        .slider-track {
          display: flex;
          width: calc(200%); /* Twice the width for continuous scroll */
          animation: scroll 20s linear infinite; /* Adjust duration for smooth scrolling */
        }

        .client-item {
          flex-shrink: 0;
        }

        /* Continuous scrolling animation */
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%); /* Move by half of the track's width */
          }
        }

        /* Faster scroll for mobile */
        @media (max-width: 768px) {
          .slider-track {
            animation-duration: 15s; /* Faster speed on mobile */
          }
        }
      `}</style>
    </section>
  );
};

export default ClientSlider;
