import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Footer = () => {
  return (
    <footer className="bg-blue-900 text-white py-8">
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* About Us */}
          <div>
            <h3 className="text-lg font-bold mb-4">About Us</h3>
            <p className="text-sm mb-4">
              Unique Technology is dedicated to offering exceptional jewellery manufacturing solutions and consultancy services. Our mission is to deliver top-notch, tailored solutions to meet your specific needs and enhance your jewellery production processes.
            </p>
            <Link to="/about" className="text-blue-300 hover:underline">Learn more about us</Link>
          </div>

          {/* Location */}
          <div>
            <h3 className="text-lg font-bold mb-4">Location</h3>
            <p className="text-sm mb-4">
              G1-31 EPIP GEM AND JEWELLERY ZONE
              <br />
              <a href="tel:9352589519" className="text-blue-300 hover:underline">9352589519</a>
            </p>
            <div className="text-sm">
              <p><strong>Email:</strong> <a href="mailto:uniquetechnology168@gmail.com" className="text-blue-300 hover:underline">uniquetechnology168@gmail.com</a></p>
              <p><strong>Follow us:</strong></p>
              <div className="flex space-x-4 mt-2">
                <a href="#" className="text-blue-300 hover:text-blue-200"><i className="fab fa-facebook-f"></i></a>
                <a href="#" className="text-blue-300 hover:text-blue-200"><i className="fab fa-twitter"></i></a>
                <a href="#" className="text-blue-300 hover:text-blue-200"><i className="fab fa-linkedin-in"></i></a>
                <a href="#" className="text-blue-300 hover:text-blue-200"><i className="fab fa-instagram"></i></a>
              </div>
            </div>
          </div>

          {/* Hours */}
          <div>
            <h3 className="text-lg font-bold mb-4">Hours</h3>
            <p className="text-sm mb-4">
              Mon - Fri: 11AM - 11PM
              <br />
              Sat: 10AM - 01AM
              <br />
              Sun: 10AM - 02AM
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-bold mb-4">Quick Links</h3>
            <ul className="text-sm space-y-2">
              <li><Link to="/" className="text-blue-300 hover:underline">Home</Link></li>
              <li><Link to="/services" className="text-blue-300 hover:underline">Services</Link></li>
              <li><Link to="/products" className="text-blue-300 hover:underline">Products</Link></li>
              <li><Link to="/contact" className="text-blue-300 hover:underline">Contact Us</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
