import React from 'react';
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <section className="relative flex flex-col items-center justify-center text-center bg-[#caddf2] h-screen px-4">
      <div className="max-w-screen-lg mx-auto">
        <h1 className="text-4xl md:text-6xl font-bold text-blue-900 mb-4">Innovative Jewellery Manufacturing Solutions</h1>
        <p className="text-lg md:text-xl text-blue-700 mb-8">
          We provide state-of-the-art manufacturing technology to create stunning jewellery pieces that captivate the world.
        </p>
        <Link to="/products" className="inline-block bg-blue-700 text-white px-6 py-3 rounded-md text-lg font-semibold hover:bg-blue-600 transition-colors duration-300">
          Explore Now
        </Link>
      </div>
    </section>
  );
}

export default Banner;
