import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';

const ServicePage = () => {
  return (
    <>
      {/* Banner Section for "Our Services" */}
      <section className="relative bg-cover bg-center bg-no-repeat h-72 flex items-center justify-center" 
        style={{ backgroundImage: 'url(https://your-stunning-background-url.com)' }}> {/* Add a stunning background URL */}
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900 to-blue-700 opacity-75"></div> {/* Gradient overlay */}
        <h2 className="relative text-4xl md:text-5xl font-bold text-white text-center">Our Services</h2>
      </section>

      {/* Services Section */}
      <section className="py-12 bg-[#f0f4f8] pt-24">
        <div className="max-w-screen-xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Image Section */}
            <div className="flex justify-center items-center">
              <img
                src="uniway1.png"
                alt="Jewellery Manufacturing Machines"
                className="w-full h-80 md:h-96 object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500 ease-in-out" // Animation on hover
              />
            </div>

            {/* Services Description */}
            <div>
              <h3 className="text-3xl font-bold text-blue-900 mb-4">Jewellery Manufacturing Machine Sales & Consultancy</h3>
              <p className="text-blue-700 mb-6 leading-relaxed">
                Unique Technology specializes in providing top-tier jewellery manufacturing machines and expert consultancy
                services for all your production needs.
              </p>
              <ul className="list-disc list-inside text-blue-700 space-y-3 mb-6">
                <li>Customized machine setup based on your business requirements</li>
                <li>Expert consultancy for jewellery manufacturing</li>
                <li>Comprehensive production planning and workflow optimization</li>
                <li>Training on advanced machinery operation</li>
                <li>Quality assurance and compliance assistance</li>
                <li>Post-installation support and maintenance services</li>
                <li>Inventory management and cost-saving strategies</li>
              </ul>

              <p className="text-blue-700 mb-6 leading-relaxed">
                We ensure that your manufacturing unit operates smoothly and efficiently with our state-of-the-art machines and industry-leading consultancy services.
              </p>

              {/* Button with Animation */}
              <Link 
                to="/contact"
                className="inline-block bg-blue-700 text-white px-6 py-3 rounded-md font-semibold hover:bg-blue-600 transition duration-300 hover:shadow-lg hover:translate-y-1 transform"
              >
                Book a Consultation
              </Link>
            </div>
          </div>

          {/* Additional Services Section */}
          <div className="mt-12">
            <h3 className="text-2xl font-bold text-blue-900 mb-4">Why Choose Us?</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-blue-700">
              <div>
                <ul className="list-disc list-inside space-y-3">
                  <li>End-to-end machine installation and setup</li>
                  <li>Streamlined production planning for optimal results</li>
                  <li>Consultancy for business expansion and growth</li>
                </ul>
              </div>
              <div>
                <ul className="list-disc list-inside space-y-3">
                  <li>Workflow optimization to boost productivity</li>
                  <li>Hands-on training for your manufacturing team</li>
                  <li>Ongoing maintenance and machine upgrades</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ServicePage;
